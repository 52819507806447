import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import AboutSlide from "./about-slide";
import Together from "../../videos/slides/together.webm";
import Dynamism from "../../videos/slides/dynamism.webm";
import Box from "../../videos/slides/box.webm";
import Customer from "../../videos/slides/customer.webm";
import {useTranslation} from "react-i18next";
import {Navigation} from "swiper";

const SwiperSection = () => {
  const {t} = useTranslation();

  return (
    <section className="swiper-section">
      <div className="swiper-section__wrapper wrapper">
        <Swiper
          modules={[Navigation]}
          spaceBetween={50}
          slidesPerView={1}
          onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper) => console.log(swiper)}
          navigation={true}
          breakpoints={{
            320: {
              slidesPerView: 1.05,
              spaceBetween: 8,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 8,
            },
          }}
        >
          <SwiperSlide>
            <AboutSlide video={Together} title={t("slides.togetherTitle")} text={t("slides.togetherText")}/>
          </SwiperSlide>
          <SwiperSlide>
            <AboutSlide video={Dynamism} title={t("slides.dynamismTitle")} text={t("slides.dynamismText")}/>
          </SwiperSlide>
          <SwiperSlide>
            <AboutSlide video={Box} title={t("slides.boxTitle")} text={t("slides.boxText")}/>
          </SwiperSlide>
          <SwiperSlide>
            <AboutSlide video={Customer} title={t("slides.customerTitle")} text={t("slides.customerText")}/>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default SwiperSection;
