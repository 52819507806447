import React from 'react';
import {useTranslation} from "react-i18next";
import WeChoseImage from "../../images/we-chose.png";

const WeChose = () => {
  const { t } = useTranslation();

  return (
    <section className="we-chose">
      <div className="we-chose__wrapper wrapper">
        <p className="we-chose__lead-text">
          {t("about.chooseLeadText")}
        </p>
        <div className="we-chose__content">
          <img src={WeChoseImage} alt="We chose" className="we-chose__image"/>
          <div className="we-chose__pink-block">
            <p>
              {t("about.choosePinkText")}
            </p>
          </div>
        </div>
        <p className="we-chose__big-text">
          {t("about.chooseBigText")}
        </p>
      </div>
    </section>
  );
};

export default WeChose;
