import React from 'react';
import {useTranslation} from "react-i18next";
import LeadVideoAbout from "../../videos/about.webm";

const LeadAbout = () => {
  const { t } = useTranslation();
  return (
    <section className="lead-about">
      <div className="lead-about__wrapper wrapper">
        <h1 className="lead-about__title big-title">{t("about.title")}</h1>
        <div className="lead-about__content">
          <div className="lead-about__text">
            <p>{t("about.leadTextLeft")}</p>
          </div>
          <div className="lead-about__pink-block">
            <p>{t("about.leadTextRight")}</p>
          </div>
        </div>
        <div className="lead-about__video-wrapper">
          <video className="lead-about-video" playsInline autoPlay loop muted>
            <source src={LeadVideoAbout} type="video/webm"/>
          </video>
        </div>
      </div>
    </section>
  );
};

export default LeadAbout;
