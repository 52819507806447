import React from 'react';
import CodeImage from "../../images/code-of-conduct.jpg";
import {useTranslation} from "react-i18next";

const Code = () => {
  const { t } = useTranslation();

  return (
    <section className="code">
      <div className="code__wrapper wrapper">
        <div className="advantages__item our-item">
          <div className="our-item__image-wrapper">
            <img src={CodeImage} alt="our code of conduct"/>
          </div>
          <h3 className="big-title our-item__title our-item__title--code our-item__title--white" dangerouslySetInnerHTML={{__html: t("code.title")}} />
          <p className="our-item__text">{t("code.text")}</p>
          <article className="conduct">
            <div className="conduct__section">
              <div className="conduct__list">
                <p className="conduct__list-item">{t("code.blueOne")}</p>
                <p className="conduct__list-item">{t("code.blueTwo")}</p>
                <p className="conduct__list-item">{t("code.blueThree")}</p>
              </div>
              <div className="conduct__color-block conduct__color-block--blue" />
            </div>
            <div className="conduct__section">
              <div className="conduct__color-block conduct__color-block--pink" />
              <div className="conduct__list">
                <p className="conduct__list-item">{t("code.pinkOne")}</p>
                <p className="conduct__list-item">{t("code.pinkTwo")}</p>
                <p className="conduct__list-item">{t("code.pinkThree")}</p>
              </div>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
};

export default Code;
