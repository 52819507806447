import React from 'react';
import {useTranslation} from "react-i18next";
import WeAreAboutImage from "../../images/about-we-are.png";

const WeAre = () => {
  const { t } = useTranslation();

  return (
    <section className="we-are">
      <div className="we-are__wrapper wrapper">
        <h2 className="we-are__title big-title">{t("about.weAreTitle")}</h2>
        <p className="we-are__text">{t("about.weAreLeadText")}</p>
        <div className="we-are__blocks-wrapper">
          <img src={WeAreAboutImage} alt="a young company of optimists" className="we-are__image"/>
          <p className="we-are__blocks-text">
            {t("about.weAreMainText")}
          </p>
          <div className="we-are__pink-block" />
        </div>
        <p className="we-are__closing-text">
          {t("about.weAreClosingText")}
        </p>
      </div>
    </section>
  );
};

export default WeAre;
