import React from 'react';

const AboutSlide = ({video, title, text}) => {
  return (
    <section className="about-slide">
      <div className="about-slide__image-wrapper">
        <video className="about-slide__video" playsInline autoPlay loop muted>
          <source src={video} type="video/webm"/>
        </video>
        <div className="about-slide__title-wrapper">
          <h4 className="about-slide__title">{title}</h4>
        </div>
      </div>
      <div className="about-slide__content-wrapper">
        <p>{text}</p>
      </div>
    </section>
  );
};

export default AboutSlide;
