import React from 'react';
import Header from "../components/sections/header";
import LeadAbout from "../components/about/lead-about";
import WeAre from "../components/about/we-are";
import WeChose from "../components/about/we-chose";
import Advantages from "../components/about/advantages";
import SwiperSection from "../components/about/swiper-section";
import Footer from "../components/sections/footer";
import Code from "../components/about/code";
import useMedia from "../hooks/useMedia";
import VideoWords from "../components/sections/video-words";

const About = () => {
  const {isMobile} = useMedia();

  return (
    <>
      <Header/>
      <LeadAbout/>
      <WeChose/>
      <WeAre/>
      <Advantages/>
      <SwiperSection />
      <Code />
      <Footer/>
    </>
  );
};

export default About;
