import React from 'react';
import AdvantageOne from "../../images/advantage-1.jpg";
import AdvantageTwo from "../../images/advantage-2.jpg";
import AdvantageThree from "../../images/advantage-3.jpg";
import {useTranslation} from "react-i18next";

const Advantages = () => {
  const { t } = useTranslation();

  return (
    <section className="advantages">
      <div className="advantages__wrapper wrapper">
        <div className="advantage__list">
          <div className="advantages__item our-item">
            <div className="our-item__image-wrapper">
              <img src={AdvantageOne} alt="our mission"/>
            </div>
            <h3 className="our-item__title big-title" dangerouslySetInnerHTML={{__html: t("advantage.advantageOneTitle")}} />
            <p className="our-item__text">{t("advantage.advantageOneText")}</p>
          </div>
          <div className="advantages__item our-item">
            <div className="our-item__image-wrapper">
              <img src={AdvantageTwo} alt="our mission"/>
            </div>
            <h3 className="our-item__title big-title" dangerouslySetInnerHTML={{__html: t("advantage.advantageTwoTitle")}} />
            <p className="our-item__text">{t("advantage.advantageTwoText")}</p>
          </div>
          <div className="advantages__item our-item">
            <div className="our-item__image-wrapper">
              <img src={AdvantageThree} alt="our mission"/>
            </div>
            <h3 className="our-item__title big-title our-item__title--white" dangerouslySetInnerHTML={{__html: t("advantage.advantageThreeTitle")}} />
            <p className="our-item__text">{t("advantage.advantageThreeText")}</p>
          </div>
        </div>

      </div>
    </section>
  );
};

export default Advantages;
